// Router config for react lasy(), Suspense and Aside menu
// For more information about structure see {@link https://gitlab.factorymarket.tech/Services/fm-operation/tree/master/src/interfaces/common/containers/Aside/README.md|Aside}

import { roles } from 'utils/auth';

export default {
  Dashboard: {
    path: '/',
    exact: true,
    showAside: true,
    icon: 'pie-chart',
    redirectRoute: '/login',
    location: 'Dashboard',
    roles: roles.admin
  },
  Users: {
    path: '/users',
    showAside: true,
    icon: 'team',
    redirectRoute: '/login',
    location: 'Users',
    roles: roles.admin,
    childrens: {
      Users: {
        path: '/users',
        showAside: true,
        icon: 'database',
        redirectRoute: '/login',
        location: 'Users',
        roles: roles.admin,
        resources: [
          {
            key: 'userMetadata',
            url: '/employee-area/user/metadata'
          }
        ]
      },
      'Users CSV': {
        path: '/user/import',
        showAside: true,
        icon: 'import',
        redirectRoute: '/login',
        location: 'Users/Import/',
        roles: roles.admin
      },
      UserAdd: {
        path: '/user/add',
        redirectRoute: '/login',
        location: 'Users/UserAdd',
        roles: roles.admin,
        resources: [
          {
            key: 'userMetadata',
            url: '/employee-area/user/metadata'
          }
        ]
      },
      Segments: {
        path: '/user-segments',
        showAside: true,
        icon: 'database',
        redirectRoute: '/login',
        location: 'UserSegments',
        roles: roles.admin
      },
      'Segments CSV': {
        path: '/user-segment/import',
        showAside: true,
        icon: 'import',
        redirectRoute: '/login',
        location: 'UserSegments/Import/',
        roles: roles.admin
      },
      UserSegmentEdit: {
        path: '/user-segment/edit/:id',
        redirectRoute: '/login',
        location: 'UserSegments/UserSegment',
        roles: roles.admin
      },
      UserSegmentAdd: {
        path: '/user-segment/add',
        redirectRoute: '/login',
        location: 'UserSegments/UserSegment',
        roles: roles.admin
      }
    }
  },
  User: {
    path: '/user/:id',
    redirectRoute: '/login',
    location: 'Users/User',
    roles: roles.admin,
    resources: [
      {
        key: 'userMetadata',
        url: '/employee-area/user/metadata'
      }
    ]
  },
  Loyalty: {
    showAside: true,
    icon: 'smile',
    path: '/loyalty',
    redirectRoute: '/login',
    roles: roles.admin,
    childrens: {
      /* Campaigns: {
        showAside: true,
        icon: 'database',
        path: '/campaigns',
        redirectRoute: '/login',
        location: 'Campaigns',
        roles: roles.admin
      },
      CampaignAdd: {
        icon: 'database',
        path: '/campaign/add',
        redirectRoute: '/login',
        location: 'Campaigns/Campaign',
        roles: roles.admin
      },
      CampaignEdit: {
        icon: 'database',
        path: '/campaign/edit/:id',
        redirectRoute: '/login',
        location: 'Campaigns/Campaign',
        roles: roles.admin
      }, */
      Terms: {
        showAside: true,
        icon: 'database',
        path: '/terms',
        redirectRoute: '/login',
        location: 'VoucherTerms',
        roles: roles.admin
      },
      TermAdd: {
        icon: 'database',
        path: '/term/add',
        redirectRoute: '/login',
        location: 'VoucherTerms/Term',
        roles: roles.admin
      },
      TermEdit: {
        icon: 'database',
        path: '/term/edit/:id',
        redirectRoute: '/login',
        location: 'VoucherTerms/Term',
        roles: roles.admin
      },
      WoF: {
        showAside: true,
        icon: 'play-circle',
        path: '/wof',
        redirectRoute: '/login',
        location: 'WoF',
        roles: roles.admin,
        resources: [
          {
            key: 'prizes',
            url: '/employee-area/loyalty/wof/prize',
            refreshTypes: ['EDIT_PRIZE_SUCCESS', 'ADD_PRIZE_SUCCESS']
          }
        ]
      },
      Vouchers: {
        showAside: true,
        icon: 'credit-card',
        path: '/vouchers/list',
        redirectRoute: '/login',
        location: 'Vouchers',
        roles: roles.admin
      },
      'Vouchers CSV': {
        path: '/vouchers/import',
        showAside: true,
        icon: 'import',
        redirectRoute: '/login',
        location: 'Vouchers/Import/',
        roles: roles.admin
      },
      Settings: {
        path: '/app-settings/loyalty',
        showAside: true,
        icon: 'setting',
        redirectRoute: '/login',
        location: 'AppSettings',
        roles: roles.admin
      },
      VoucherEdit: {
        path: '/voucher/edit/:id',
        redirectRoute: '/login',
        location: 'Vouchers/Voucher',
        roles: roles.admin
      },
      VoucherAdd: {
        path: '/voucher/add',
        redirectRoute: '/login',
        location: 'Vouchers/Voucher',
        roles: roles.admin
      }
    }
  },
  Products: {
    showAside: true,
    icon: 'appstore',
    path: '/products',
    redirectRoute: '/login',
    roles: roles.admin,
    childrens: {
      'Products list': {
        path: '/products/list',
        showAside: true,
        icon: 'bars',
        redirectRoute: '/login',
        location: 'Products/List',
        roles: roles.admin
      },
      'Categories list': {
        path: '/categories/list',
        showAside: true,
        icon: 'bars',
        redirectRoute: '/login',
        location: 'Categories/',
        roles: roles.admin
      },
      'Groups list': {
        path: '/groups/list',
        showAside: true,
        icon: 'bars',
        redirectRoute: '/login',
        location: 'Groups/',
        roles: roles.admin
      },
      Reviews: {
        path: '/products/reviews',
        showAside: true,
        icon: 'bars',
        redirectRoute: '/login',
        location: 'Reviews/',
        roles: roles.admin
      },
      'Reviews CSV': {
        path: '/products/review/import',
        showAside: true,
        icon: 'import',
        redirectRoute: '/login',
        location: 'Reviews/Import/',
        roles: roles.admin
      },
      PimImport: {
        path: '/pimimport',
        showAside: true,
        icon: 'bars',
        redirectRoute: '/login',
        location: 'PimImport',
        roles: roles.admin
      }
    }
  },
  PimImportErrors: {
    icon: 'database',
    path: '/pimmessages/:type/:id',
    redirectRoute: '/login',
    location: 'PimImport/Messages',
    roles: roles.admin
  },
  Product: {
    path: '/product/show/:id',
    redirectRoute: '/login',
    location: 'Products/Product',
    roles: roles.admin,
    resources: [
      {
        key: 'categoriesTree',
        url: '/employee-area/product/category/tree'
      }
    ]
  },
  Category: {
    path: '/category/edit/:id',
    redirectRoute: '/login',
    location: 'Categories/Category',
    roles: roles.admin,
    resources: [
      {
        key: 'categoriesTree',
        url: '/employee-area/product/category/tree'
      }
    ]
  },
  CategoryAdd: {
    path: '/category/add',
    redirectRoute: '/login',
    location: 'Categories/Category',
    roles: roles.admin,
    resources: [
      {
        key: 'categoriesTree',
        url: '/employee-area/product/category/tree'
      }
    ]
  },
  Group: {
    path: '/group/edit/:id',
    redirectRoute: '/login',
    location: 'Groups/Group',
    roles: roles.admin
  },
  GroupAdd: {
    path: '/group/add',
    redirectRoute: '/login',
    location: 'Groups/Group',
    roles: roles.admin
  },
  Stores: {
    path: '/stores',
    showAside: true,
    icon: 'shop',
    redirectRoute: '/login',
    location: 'Stores',
    roles: roles.admin
  },
  Store: {
    path: '/store/edit/:id',
    redirectRoute: '/login',
    location: 'Stores/Store',
    roles: roles.admin
  },
  StoreAdd: {
    path: '/store/add',
    redirectRoute: '/login',
    location: 'Stores/Store',
    roles: roles.admin
  },
  Banners: {
    path: '/banners',
    showAside: true,
    icon: 'switcher',
    redirectRoute: '/login',
    location: 'Banners',
    roles: roles.admin
  },
  Banner: {
    path: '/banner/edit/:id',
    redirectRoute: '/login',
    location: 'Banners/Banner',
    roles: roles.admin
  },
  BannerAdd: {
    path: '/banner/add',
    redirectRoute: '/login',
    location: 'Banners/Banner',
    roles: roles.admin
  },
  Notifications: {
    showAside: true,
    icon: 'bell',
    path: '/notifications',
    redirectRoute: '/login',
    roles: roles.admin,
    childrens: {
      Pushes: {
        showAside: true,
        icon: 'message',
        path: '/notifications',
        redirectRoute: '/login',
        location: 'Notifications',
        roles: roles.admin
      },
      Notification: {
        path: '/notification/edit/:id',
        redirectRoute: '/login',
        location: 'Notifications/Notification',
        roles: roles.admin
      },
      NotificationAdd: {
        path: '/notification/add',
        redirectRoute: '/login',
        location: 'Notifications/Notification',
        roles: roles.admin
      },
      Journal: {
        path: '/notification/journal',
        showAside: true,
        icon: 'bars',
        redirectRoute: '/login',
        location: 'Journal',
        roles: roles.admin
      }
    }
  },
  'Content Pages': {
    path: '/content-pages/settings/content_page',
    showAside: true,
    icon: 'setting',
    redirectRoute: '/login',
    location: 'AppSettings',
    roles: roles.admin
  },
  'Content eMails': {
    path: '/content-emails/settings/content_email',
    showAside: true,
    icon: 'setting',
    redirectRoute: '/login',
    location: 'AppSettings',
    roles: roles.admin
  },
  'App Settings': {
    path: '/app-settings/client_force_update',
    showAside: true,
    icon: 'setting',
    redirectRoute: '/login',
    location: 'AppSettings',
    roles: roles.admin
  },
  Login: {
    path: '/login',
    location: 'Login',
    exact: true,
    roles: roles.guest
  },
  'Forgot password': {
    path: '/password/forgot',
    location: 'Password/Forgot',
    exact: true,
    roles: roles.guest
  },
  'New password': {
    path: '/restore',
    location: 'Password/Restore',
    roles: roles.guest
  },
  NoMatch: {
    location: '404',
    roles: roles.all
  }
};
