import { take, put, race } from 'redux-saga/effects';
import { getUsersImport, ADD_USERS_IMPORT_SUCCESS } from './actions';

export function* usersImportSaga() {
  while (true) {
    const { success } = yield race({
      success: take(ADD_USERS_IMPORT_SUCCESS)
    });
    if (success) {
      yield put(getUsersImport({}));
    }
  }
}

export default usersImportSaga;
